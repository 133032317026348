const initialState = {
	// carriers_data: [],
	carriers_data: {},
	sales_chart: [],
	past_carriers_data: [],
	total_account_data: {'sales': 0, refill_amount: 0, clicks: 0, impressions: 0, spend: 0},
}

const dashboardReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_DASHBOARD_DATA':
			return {
				...state,
				carriers_data: [...action.payload.carriers_data],
				sales_chart: [...action.payload.sales_chart],
				// carriers_data: {...state.carriers_data, 'result': action.payload.carriers_data, 'count': action.payload.carriers_data.length},
				past_carriers_data: [...action.payload.past_carriers_data],
				total_account_data: {...state.total_account_data, ...action.payload.total_account_data},
			}

		default:
			return { ...state }

	}
}

export default dashboardReducer